import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {group: "HOME"},
        component: Home
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {group: "PROFILE"},
        component: () => import('../views/profile/Profile.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        meta: {group: "PRIVACY"},
        component: () => import('../views/privacy/Privacy.vue')
    },
    {
        path: '/security',
        name: 'Security',
        meta: {group: "SECURITY"},
        component: () => import('../views/security/Security.vue')
    },
    {
        path: '/developers',
        name: 'Developers',
        meta: {group: "DEVELOPER_CENTER"},
        component: () => import('../views/developer/Developer.vue')
    },
    {
        path: '/developers/projects',
        name: 'Projects',
        meta: {group: "DEVELOPER_CENTER"},
        component: () => import('../views/developer/Projects.vue')
    },
    {
        path: '/developers/projects/:id',
        name: 'Project',
        meta: {group: "DEVELOPER_CENTER"},
        component: () => import('../views/developer/Project.vue')
    },
    {
        path: '/developers/projects/apps/oauth2/:id',
        name: 'OAuth2App',
        meta: {group: "DEVELOPER_CENTER"},
        component: () => import('../views/developer/apps/OAuth2App.vue')
    },
    {
        path: '/login',
        name: "Login",
        meta: {outer: true, skipAuth: true},
        component: () => import('../views/auth/Login.vue')
    },
    {
        path: '/reset_password',
        name: "PasswordReset",
        meta: {outer: true, skipAuth: true},
        component: () => import('../views/auth/PasswordReset.vue')
    },
    {
        path: '/verify_authentication',
        name: "VerifyAuthentication",
        meta: {outer: true, skipAuth: true},
        component: () => import('../views/auth/VerifyAuthentication.vue')
    },
    {
        path: '/register',
        name: "Register",
        meta: {outer: true, skipAuth: true},
        component: () => import('../views/auth/Register.vue')
    },
    {
        path: '/switch',
        name: "Switch User",
        meta: {outer: true, skipAuth: true},
        component: () => import('../views/auth/ChangeUser.vue')
    },
    {
        path: '/iaauth/:id',
        name: "IAAuth",
        meta: {outer: true},
        component: () => import('../views/authorization/IAAuth.vue')
    },
    {
        path: '/auth/oauth2',
        name: "OAuth2",
        meta: {outer: true},
        component: () => import('../views/authorization/OAuth2.vue')
    },
    {
        path: '/login/oauth2/:id',
        name: "OAuth2",
        meta: {outer: true},
        component: () => import('../views/authorization/OAuth2.vue')
    },
    {
        path: '/*',
        name: "NotFound",
        meta: {outer: false},
        component: () => import('../views/NotFound.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.name + " | InteraApps Accounts"
    next()
})

export default router
