<template>
    <div v-if="$route.meta.outer">
        <router-view/>
    </div>
    <div id="app" v-else>
        <MobileNav/>
        <SideNav/>
        <router-view id="page"/>
    </div>
</template>
<script>
import SideNav from "./components/SideNav";
import MobileNav from "./components/MobileNav";

export default {
    components: {
        SideNav,
        MobileNav
    }
}
</script>