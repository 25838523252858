<template>
    <div class="home" style="overflow: hidden;">
        <img id="profile-picture" :style="{'border': $store.state.user.favorite_color+' 4px solid'}"
             :src="$store.state.user.profile_picture">
        <h1 id="welcome-message">Welcome back, {{ $store.state.user.full_name }}</h1>
        <div id="home-buttons">
            <router-link to="/profile" class="home-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person"
                     viewBox="0 0 16 16">
                    <path
                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                </svg>
                <div>
                    <h1>Profile</h1>
                    <p>Change some personal information</p>
                </div>
            </router-link>
            <router-link to="/security" class="home-button">
                <svg style="padding: 16px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-shield" viewBox="0 0 16 16">
                    <path
                        d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                </svg>
                <div>
                    <h1>Security</h1>
                    <p>Check the health of your account</p>
                </div>
            </router-link>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {}
}
</script>
<style lang="scss" scoped>
#profile-picture {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: block;
    margin: 80px auto auto;
}

#welcome-message {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    color: #434343;
    padding: 0 10px;
    font-weight: 500;
    font-size: 28px;
}

#home-buttons {
    display: block;
    margin: auto;
    width: fit-content;

    .home-button {
        display: inline-block;
        vertical-align: middle;
        margin: 20px 10px;
        text-decoration: none;
        color: #656565;
        width: 390px;
        padding: 7px 13px;
        border-radius: 20px;

        svg {
            width: 70px;
            height: 70px;
            padding: 13px;
            border-radius: 11px;
            background: #00000009;
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
        }

        div {
            display: inline-block;
            vertical-align: middle;
            word-break: break-all;
            width: calc(100% - 120px);

            h1 {
                font-size: 25px;
                font-weight: 500;
            }

            p {
                font-size: 18px;
                font-weight: 500;
                width: calc(100% - 30px);
                word-break: break-word;
            }
        }

        &:hover {
            color: #4542F4;
            cursor: pointer;
            background: #4542F40A;

            svg {
                background: #4542F411;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    #profile-picture {
        width:  110px;
        height: 110px;
        margin-top: 100px;
    }

    #welcome-message {
        margin-top: 50px;
        margin-bottom: 50px;
        font-size: 24px;
    }

    #home-buttons {
        width: 100%;
        padding: 10px;

        .home-button {
            display: block;
            width: calc(100% - 20px);
            padding: 10px;

            svg {
                width: 70px;
                height: 70px;
            }

            div {
                h1 {
                    font-size: 22px;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }


}

</style>