import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            logged_in: true,
            profile_picture: "",
            favourite_color: "#FF4343",
            name: "loading..."
        },
        app: {
            sideNavOpened: false,
            turnStileLoaded: false
        }
    },
    mutations: {},
    actions: {},
    modules: {}
})
