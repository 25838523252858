import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import './registerServiceWorker'
import IAAccounts from './IAAccounts'
import {eventBus} from './helper'
require("./assets/css/app.scss")
Vue.config.productionTip = false

const apiClient = new IAAccounts(localStorage["authkey"] ? localStorage["authkey"] : '', store)

if (localStorage["authkey"]) {
    apiClient.options.header["x-auth-key"] = localStorage["authkey"]
}

Vue.mixin({
    data: () => ({
        apiClient
    })
})


window.onloadTurnstileCallback = () => {
    store.state.app.turnStileLoaded = true
    eventBus.$emit('turnStileLoaded')
}

if (window.frameElement) {
    document.write("Cannot load into an iFrame")
} else {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')


    apiClient.loadUsersAndInsert()
        .catch(() => {
            if (!router.currentRoute.meta.skipAuth) {
                let redirectUri = null
                if (!router.currentRoute.path.includes("://"))
                    redirectUri = encodeURIComponent(router.currentRoute.fullPath)
                router.replace("/login" + (redirectUri ? "?redirect=" + redirectUri : ""))
            }
        })
}
