import {PrajaxClient} from "cajaxjs";

class IAAccounts extends PrajaxClient {
    constructor(apiKey, store) {
        super({
            baseUrl: process.env.VUE_APP_API_BASE,
            options: {
                json: true,
                header: {
                    "x-auth-key": apiKey
                }
            }
        })
        this.store = store

        this.apiKey = apiKey
        this.errorCheck = (res) => {
            res = res.json()
            if (!res.success) {
                console.log("ERR");
                const error = new Error()
                if (res.message)
                    error.message = res.message
                if (res.exception)
                    error.name = res.exception
                throw error
            }
            return res
        }
    }

    loadUser() {
        return this.get("/api/v2/user")
            .then(this.errorCheck)
    }

    loadUsersAndInsert() {
        return this.loadUser().then(user => {
            this.store.state.user = user
            this.store.state.user.logged_in = true;
            return user
        })
    }

    async setApiKey(apiKey) {
        localStorage["authkey"] = apiKey
        this.options.header["x-auth-key"] = apiKey
        return await this.loadUsersAndInsert()
    }

    authKeys(filter = null) {
        return this.get("/api/v2/authkeys", {filter})
            .then(this.errorCheck)
            .then(res => res.data)
    }

    authorizedIAAuthApps() {
        return this.get("/api/v2/iaauth/authorized_apps")
            .then(this.errorCheck)
            .then(res => res.data)
    }

    authorizedOAuthApps() {
        return this.get("/api/v2/authorization/oauth2/authorized_apps")
            .then(this.errorCheck)
            .then(res => res.data)
    }

    ungrantIAAuthApp(id) {
        return this.delete("/api/v2/iaauth/authorized_apps/" + id)
            .then(this.errorCheck)
    }

    revokeOAuthApp(id) {
        return this.get("/api/v2/authorization/oauth2/apps/" + id + "/revoke")
            .then(this.errorCheck)
    }

    removeAuthKey(key) {
        return this.delete("/api/v2/authkeys/" + key)
            .then(this.errorCheck)
    }

    addUserAuthkey(userId, authKey) {
        try {
            if (!localStorage["authkeys"]) {
                localStorage["authkeys"] = JSON.stringify({})
            }
            JSON.parse(localStorage["authkeys"])
        } catch (e) {
            localStorage["authkeys"] = JSON.stringify({})
        }

        const authKeys = JSON.parse(localStorage["authkeys"])
        authKeys[userId] = authKey
        localStorage["authkeys"] = JSON.stringify(authKeys)
    }
}

export default IAAccounts